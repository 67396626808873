<template>
  <!-- form -->
  <validation-observer ref="simpleRules">
    <b-form autocomplete="off">
      <!-- disable auto fill -->
      <input
        type="password"
        name="pwd"
        autocomplete="new-password"
        hidden
      >

      <b-row>
        <b-col
          v-if="settingType === 'NEW'"
          md="6"
        >
          <b-form-group>
            <label class="font-weight-bolder">
              {{ $t('User Name') }}
            </label>
            <validation-provider
              #default="{ errors }"
              name="User Name"
              rules="required|min:1|max:16"
            >
              <b-input-group
                :prepend="String(prefix).toLowerCase()"
              >
                <b-form-input
                  v-model="formData.username"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('User Name')"
                  autocomplete="off"
                />
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-else
          md="6"
        >
          <b-form-group>
            <label class="font-weight-bolder">
              {{ $t('User Name') }}
            </label>
            <validation-provider
              #default="{ errors }"
              name="User Name"
              rules="required|min:1|max:16"
            >
              <b-form-input
                v-model="formData.username"
                :state="errors.length > 0 ? false:null"
                autocomplete="off"
                disabled
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group>
            <label class="font-weight-bolder">{{ $t('Contact') }}</label>
            <validation-provider
              #default="{ errors }"
              name="Contact"
              rules="min:1|max:16"
            >
              <b-form-input
                v-model="formData.contact"
                :state="errors.length > 0 ? false:null"
                :placeholder="$t('Contact')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label class="font-weight-bolder">{{ $t('Password') }}</label>
            <validation-provider
              #default="{ errors }"
              name="Password"
              :rules="passwordRules"
            >
              <b-form-input
                v-model="formData.password"
                :state="errors.length > 0 ? false:null"
                :placeholder="$t('Password')"
                type="password"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <label class="font-weight-bolder">{{ $t('Currency') }}</label>
            <v-select
              v-model="formData.currency"
              :label="$t('Currency')"
              :options="['THB']"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="settingType==='NEW'"
          md="6"
        >
          <b-form-group>
            <label class="font-weight-bolder">{{ $t('Credit') }}</label>
            <validation-provider
              #default="{ errors }"
              name="Credit"
              rules="required|min_value:0"
            >
              <b-form-input
                v-model.number="formData.creditLimit"
                :state="errors.length > 0 ? false:null"
                :placeholder="$t('Credit')"
                type="number"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-else
          md="6"
        >
          <b-form-group>
            <label class="font-weight-bolder">{{ $t('Credit') }}</label>
            <b-form-input
              :value="formData.creditLimit"
              :placeholder="$t('Credit')"
              type="number"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
        >
          <b-form-group>
            <b-form-group>
              <label class="font-weight-bolder">{{ $t('MaxCreditLimit') }}</label>
              <validation-provider
                #default="{ errors }"
                name="MaxCreditLimit"
                rules="required|min_value:0"
              >
                <b-form-input
                  v-model.number="formData.maxCreditLimit"
                  :state="errors.length > 0 ? false:null"
                  :placeholder="$t('MaxCreditLimit')"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          md="6"
        >
          <b-form-checkbox
            v-model="formData.active"
            class="custom-control-success"
            switch
          >
            <span class="h5 font-weight-bolder">{{ $t('Suspend / Active') }}</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'

import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCardText, BFormRadioGroup,
} from 'bootstrap-vue'
import '@validations'
import { AgentDownlineListsByCurrentType } from '@/utils/agent'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormRadioGroup,

    vSelect,
  },
  props: {
    currentAgentType: {
      type: String,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        username: '',
        contact: '',
        currency: 'THB',
        password: '',
        creditLimit: 0,
        maxCreditLimit: 0,
      },
    }
  },
  computed: {
    optionsTypes() {
      const lists = AgentDownlineListsByCurrentType(this.currentAgentType)

      return lists.map(list => ({ text: String(list).toUpperCase(), value: String(list).toLowerCase() }))
    },
    prefix() {
      return store.state.accountCreateSetting.prefix
    },
    settingType() {
      return store.getters['accountCreateSetting/getSettingType']
    },
    passwordRules() {
      if (this.settingType === 'NEW') {
        return 'required|min:6|max:16|password'
      }
      return 'min:6|max:16|password'
    },
  },
  mounted() {
    // localize('th')
  },
  methods: {
    AgentDownlineListsByCurrentType,
    async validationForm() {
      return this.$refs.simpleRules.validate()
    },
    onChangeLevel() {
      this.$emit('update:formData', { ...this.formData, username: '' })
      this.$refs.simpleRules.reset();
    },
  },
}
</script>
