<template>
  <b-card no-body>
    <div v-if="!$wait.is('loading-get-setting')">
      <b-row no-gutters>
        <b-col
          cols="12"
          class="bg-primary"
        >
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div>
              <b-button
                variant="primary"
                class="btn-icon"
                :disabled="$wait.is('loading-on-save')"
                @click="$router.go(-1)"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                {{ this.$t('Previous Page') }}
              </b-button>
            </div>
            <div class="card-header text-white font-weight-bold h4 ">
              {{ $t('Member Info') }}
            </div>
            <div class="pr-1">
              <b-button
                variant="dark"
                class="btn-icon"
                size="lg"
                :disabled="$wait.is('loading-on-save')"
                @click="onSave"
              >
                <feather-icon icon="SaveIcon" />
                {{ textButtonSave }}
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="p-2"
        >
          <MembersCreateForm
            ref="formData"
            :form-data.sync="formData"
            :current-agent-type="GetAgentGroupType(userData)"
          />
        </b-col>
      </b-row>
      <b-row
        no-gutters
      >
        <b-col
          cols="12"
          class="bg-primary"
        >
          <div class="card-header text-white font-weight-bold h4">
            {{ this.$t('Setting Games') }}
          </div>
        </b-col>
        <b-col
          cols="12"
          class="p-2 setting-games"
        >
          <AccountFormSettingGamesComponent />
        </b-col>
      </b-row>

    </div>
    <div v-else>
      <loading />
    </div>
  </b-card>
</template>

<script>
import MembersCreateForm from '@/views/members/MembersCreateForm'
import AccountFormSettingGamesComponent from '@/components/settings/AccountFormSettingGamesComponent'
import store from '@/store'
import { get, omit, pick } from 'lodash'
import { GetAgentGroupId, GetAgentGroupUsername, GetAgentGroupType } from '@/utils/agent'

export default {
  components: {
    MembersCreateForm,
    AccountFormSettingGamesComponent,
  },
  data() {
    return {
      formData: {
        username: '',
        contact: '',
        currency: 'THB',
        password: '',
        creditLimit: 0,
        maxCreditLimit: 0,
        active: true,
      },

      editId: '',
    }
  },
  computed: {
    userData() {
      return store.getters['auth/user']
    },
    currentAgentType() {
      return this.GetAgentGroupType(this.userData)
    },
    setting() {
      return store.getters['accountCreateSetting/getSetting']
    },
    settingCommission() {
      return store.getters['accountCreateSetting/getSettingCommission']
    },
    settingLimit() {
      return store.getters['accountCreateSetting/getSettingLimit']
    },
    settingType() {
      return store.getters['accountCreateSetting/getSettingType']
    },
    textButtonSave() {
      return this.settingType === 'NEW' ? this.$t('Create Member') : this.$t('Save')
    },
  },
  mounted() {
    const type = this.$route.name === 'members-edit' ? 'EDIT' : 'NEW'
    store.commit('accountCreateSetting/UPDATE_SETTING_TYPE', type)
    if (this.settingType === 'EDIT') {
      this.editId = this.$route.params.id
    }
    this.initFormData()
    this.getSetting()
  },
  methods: {
    GetAgentGroupId,
    GetAgentGroupUsername,
    GetAgentGroupType,
    initFormData() {

    },
    async getSetting() {
      this.$wait.start('loading-get-setting')
      try {
        const path = this.settingType === 'NEW' ? '/agent/accounts/group/init-setting' : `/member/accounts/group/init-setting/${this.editId}`
        const { data } = await this.$http.get(path, {
          params: {
            type: "MEMBER",
          },
        })
        if (data.success) {
        // cloneObject
          const userSetting = JSON.parse(JSON.stringify(data.data.user.setting));
          const userSettingCommission = JSON.parse(JSON.stringify(data.data.user.settingCommission));
          const userSettingLimit = JSON.parse(JSON.stringify(data.data.user.settingLimit));
          const initCommissionFrontend = JSON.parse(JSON.stringify(data.data?.initCommissionFrontend ?? {}));

          store.commit('accountCreateSetting/INIT_COMMISSION_FRONTEND', initCommissionFrontend)

          store.commit('accountCreateSetting/INIT_SETTING', userSetting)
          store.commit('accountCreateSetting/INIT_SETTING_COMMISSION', userSettingCommission)
          store.commit('accountCreateSetting/INIT_SETTING_LIMIT', userSettingLimit)

          store.commit('accountCreateSetting/UPDATE_SETTING', data.data.user.setting)
          store.commit('accountCreateSetting/UPDATE_SETTING_COMMISSION', data.data.user.settingCommission)
          store.commit('accountCreateSetting/UPDATE_SETTING_LIMIT', data.data.user.settingLimit)

          if (this.settingType === 'NEW') {
            store.commit('accountCreateSetting/UPDATE_UPLINE_SETTING', { ...userSetting })
            store.commit('accountCreateSetting/UPLINE_INIT_SETTING_COMMISSION', { ...userSettingCommission })
            store.commit('accountCreateSetting/UPLINE_INIT_SETTING_LIMIT', { ...userSettingLimit })
          } else {
            const uplineSetting = JSON.parse(JSON.stringify(data.data.user.upline.setting));
            const uplineSettingCommission = JSON.parse(JSON.stringify(data.data.user.upline.settingCommission));
            const uplineSettingLimit = JSON.parse(JSON.stringify(data.data.user.upline.settingLimit));

            store.commit('accountCreateSetting/UPDATE_UPLINE_SETTING', { ...uplineSetting })
            store.commit('accountCreateSetting/UPLINE_INIT_SETTING_COMMISSION', { ...uplineSettingCommission })
            store.commit('accountCreateSetting/UPLINE_INIT_SETTING_LIMIT', { ...uplineSettingLimit })
          }

          store.commit('accountCreateSetting/UPDATE_SETTING_STATUS', data.data.upline_status)
          store.commit('accountCreateSetting/UPDATE_SETTING_AGENT_PREFIX', data.data.prefix)
          store.commit('accountCreateSetting/UPDATE_SETTING_IS_MEMBER', true)

          const { user } = data.data

          if (this.settingType === 'NEW') {
            this.formData = {
              username: '',
              contact: '',
              currency: 'THB',
              password: '',
              creditLimit: 0,
              maxCreditLimit: 0,
              active: true,
            }
          } else {
            this.formData = {
              username: user.username,
              contact: user.contact,
              currency: user.currency,
              password: '',
              creditLimit: user.creditLimit,
              maxCreditLimit: user.maxCreditLimit,
              active: user.active,
            }
          }
        }
      } finally {
        this.$wait.end('loading-get-setting')
      }
    },
    async onSave() {
      const isValidForm = await this.$refs.formData.validationForm()
      if (!isValidForm) {
        return
      }

      const { formData } = this

      this.$wait.start('loading-on-save')
      try {
        const path = this.settingType === 'NEW' ? '/member/accounts/create' : `/member/accounts/edit`

        let body = {}
        if (this.settingType === 'NEW') {
          body = {
            setting: this.setting,
            settingCommission: this.settingCommission,
            settingLimit: this.settingLimit,
            ...formData,
          }
        } else {
          body = {
            setting: this.setting,
            settingCommission: this.settingCommission,
            settingLimit: this.settingLimit,
            // ...omit(formData, ['createNewType']),
            id: this.editId,
            ...formData,
          }
        }

        const { data } = await this.$http.post(path, body)
        if (data.success) {
          this.$swal({
            icon: 'success',
            title: this.$t('ACCOUNT_SAVE_SUCCESS'),
          })

          if (this.settingType === 'NEW') {
            this.$router.push({ name: 'members' })
          }
        } else {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
        }
      } finally {
        this.$wait.end('loading-on-save')
      }
    },
  },
}
</script>

<style>
.setting-games {
    min-height: 800px;
}
</style>
